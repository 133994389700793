<template>
  <div class="newslettern newsletter-form floating-form" :class="addClass">

    <load-script v-if="!!siteKey" src="https://www.google.com/recaptcha/api.js?onload=onCaptchaAPIReady&render=explicit"/>

    <slot name="title"/>
    <form action="" method="post" @submit.prevent="validateAndSend">

      <div class="newslettern__input floating-label">
        <input
            v-model="emailField"
            @blur="checkEmailValid(), isEmailEmpty()"
            type="email"
            id="newsletter-email"
            class="floating-input"
            :class="{ 'input-error': hasErrorValidEmail || hasErrorEmail }"
            :aria-describedby="emailDescribedby"
            name="newsletter-email">
        <label
            for="newsletter-email" class="label_float"
            :class="{ 'fixed': isFixedPlaceholder }"
            ref="emailLabel">{{ emailPlaceholder }} <sup>*</sup></label>
        <span class="highlight"></span>
        <p v-if="hasErrorValidEmail" id="newsletter-email-error" class="newslettern__error">{{ validationMsg.emailFormat }}</p>
        <p v-if="hasErrorEmail" id="newsletter-email-error" class="newslettern__error">{{ validationMsg.emailEmpty }}</p>
      </div>

      <div class="newslettern__select" id="newsletterSelectComponent">
        <div class="newsletter__select_box floating-label">
          <select v-model="countryField" name="newsletterSelect" class="floating-select" id="newsletterSelect"/>
          <span class="highlight"></span>
          <p id="newsletter-country-error" class="newslettern__error">{{ validationMsg.dataSelected }}</p>
        </div>
      </div>
      <div class="fieldset newslettern__input floating-label">
        <input v-model="firstnameField"
               @blur="isFirstNameEmpty()" type="text" id="first-name" class="floating-input"/>
        <label for="first-name" class="label_float" :class="{ 'fixed': !hasFirstName }">{{ firstName }} <sup>*</sup></label>
        <span class="highlight"></span>
        <p v-if="hasErrorFirstName" id="newsletter-firstname-error" class="newslettern__error">{{ validationMsg.firstNameEmpty }}</p>
      </div>
      <div class="newslettern__select" v-clickoutside="closeDropdown" v-bind:class="{ openMultiple: hideDropdown }">
        <label class="select-multiple__label" @click="showDropdown()" type="button">{{ skinConcernText }}<span v-if="inputs != ''">{{ inputs }}</span> <sup>*</sup></label>
        <div>
          <fieldset class="select-multiple__fieldset">
            <ul class="select-multiple__list">
              <li class="select-option__item" v-for="skin in skinConcern">
                <input type="checkbox" :id="skin" panel-focusable="" class="select-option__input"
                       v-model="inputs"
                       :value="skin"
                       :disabled="inputs.length > 2 && inputs.indexOf(skin) === -1"
                       number>
                <label :for="skin" class="select-option__label">
                  <span>{{ skin }}</span>
                </label>
              </li>
            </ul>
          </fieldset>
          <p v-if="this.hasSkinEmpty" id="newsletter-skin-error" class="newslettern__error">{{ this.skinEmpty }}</p>
        </div>
      </div>

      <div class="column full-width group-field newslettern__select newslettern__select--inline">
        <span class="dob">{{ dob }}</span>
        <div class="newslettern__select_box newslettern__select_box--month floating-label">
          <div class="selected-deault" :class="{ selectedRadio:yourMonth }">
            <select v-model="monthField" name="newsletterSelect" id="month" class="floating-select" @blur="yourMonth = true">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <label for="month" class="label_float">MM</label>
          </div>

          <span class="highlight"></span>

        </div>
        <div class="newslettern__select_box newslettern__select_box--year floating-label">
          <div class="selected-deault" :class="{ selectedRadio:yourYear }">
            <select v-model="yearField" id="dob" class="floating-select" @blur="yourYear = true">
              <option v-for="year in years" :value="year">{{ year }}</option>
            </select>
            <label for="dob" class="label_float">YYYY</label>
          </div>
          <span class="highlight"></span>
        </div>
      </div>

      <div class="newslettern__terms">
        <div class="newslettern__checkbox">
          <input
              id="terms-of-use"
              class="is-sr-only"
              v-model="termsField"
              type="checkbox"
              :aria-describedby="termsDescribedby"
              name="terms-of-use">
          <label for="terms-of-use">
            <slot name="terms"/>
          </label>
        </div>
        <p v-if="hasErrorTerms" id="terms-of-use-error" class="newslettern__error">{{ validationMsg.terms }}</p>
      </div>

      <div class="newslettern__captcha" v-if="!!siteKey">
        <div id="letter-recaptcha" class="g-recaptcha"/>
        <p v-if="hasErrorCapcha" class="newslettern__error">{{ validationMsg.captcha }}</p>
      </div>

      <div class="btn btn--black">
        <button type="submit"
                :disabled="!isSumbitEnabled"
                :class="{ 'disabled': !isSumbitEnabled }"
                v-tag:useractionevent="{ 'category': 'registration', 'action': 'newsletter', 'label': 'body' }"
                class="btn__wrapper">{{ submitBtnText }}
        </button>
      </div>
      <p v-if="serverResponse !== null"
         class="newslettern__response"
         :class="{ 'error': !serverResponse }">{{ confirmMsg }}</p>
    </form>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import {tag} from '@Foundation/analyticsHandler';
import {postData} from '@Foundation/services';
import LoadScript from '@Foundation/loadscript/loadscript';
import {clickoutside} from '@Foundation/directives/clickoutside/clickoutside';

export default {
  name: 'subscription',

  directives: {
    tag,
    clickoutside,
  },

  components: {
    LoadScript
  },

  props: {
    addClass: {type: String, default: ''},
    apiUrl: {type: String, default: ''},
    successMsg: {type: String, default: ''},
    countryCode: {type: String, default: ''},
    errorMsg: {type: String, default: ''},
    siteKey: {type: String, default: ''},
    emailPlaceholder: {type: String, default: ''},
    submitBtnText: {type: String, default: ''},
    firstName: {type: String, default: ''},
    dataSkinConcern: {type: Array, required: false},
    skinConcernText: {type: String, default: ''},
    dob: {type: String, default: ''},
    validationMsg: {
      type: Object, default: () => {
        return {
          emailFormat: 'Please use valid email format.',
          emailEmpty: 'Please enter your email address.',
          terms: 'Please accept the terms of use.',
          captcha: 'Please check the captcha',
          dataSelected: 'Please select the country',
          firstNameErrorMsg: 'Please enter your first name.',
        };
      }
    }
  },

  data() {
    return {
      skinConcern: this.dataSkinConcern,
      isBirthValid: true,
      isSumbitEnabled: false,
      serverResponse: null,
      emailField: '',
      firstnameField: '',
      countryField: '',
      monthField: '',
      yearField: '',
      termsField: false,
      hasErrorValidEmail: false,
      hasErrorEmail: false,
      hasErrorFirstName: false,
      hasFirstName: true,
      hasSkinEmpty: false,
      hasErrorTerms: false,
      hasErrorCapcha: false,
      countryCodeData: this.countryCode,
      yourYear: false,
      yourMonth: false,
      inputs: [],
      hideDropdown: false,
      skinEmpty: 'Please enter SKIN Concern.',
      captchaValue: '',
      captchaEnabled: false,
    };
  },

  computed: {
    confirmMsg() {
      return this.serverResponse ? this.successMsg : this.errorMsg;
    },
    years() {
      const year = new Date().getFullYear() - 1;
      return Array.from({length: year - 1900}, (value, index) => 1901 + index).slice().reverse();
    },
    fetchUrl() {
      return `${window.location.origin}/${this.apiUrl}`;
    },
    canSubmit() {
      var str = this.countryCode;
      var strArr = str.split(',');
      if (strArr.length > 1) {
        return !!this.emailField && !this.hasErrorValidEmail && this.termsField && this.countryField && !this.hasSkinEmpty && !!this.firstnameField && !this.hasErrorFirstName;
      } else {
        return !!this.emailField && !this.hasErrorValidEmail && this.termsField && !!this.firstnameField && !this.hasSkinEmpty && !this.hasErrorFirstName;
      }
    },
    isFixedPlaceholder() {
      return (!!this.emailField && this.hasErrorValidEmail) || (this.emailField !== '' && !this.hasErrorValidEmail && !this.hasErrorEmail) || (this.emailField !== '' && !this.hasErrorValidEmail);
    },
    emailDescribedby() {
      if (this.hasErrorValidEmail || this.hasErrorEmail) {
        return 'newslettern-email-error';
      }
      return false;
    },
    termsDescribedby() {
      if (this.hasErrorTerms) {
        return 'terms-of-use-error';
      }
      return false;
    },
    filteredList() {
      return this.filters.filter(item => {
        return item.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },

  watch: {
    canSubmit(value) {
      this.isSumbitEnabled = value;
    },
    termsField() {
      this.checkTermsValid();
    }
  },

  created() {
    window.onCaptchaAPIReady = this.onCaptchaAPIReady;
  },
  mounted() {
    var str = this.countryCode;
    var strArr = str.split(',');
    var newsletterSelect = '';
    for (var i = 0; i < strArr.length; i++) {
      if (i === 0) {
        newsletterSelect += "<option value='' selected>" + strArr[i] + '</option>';
      } else {
        newsletterSelect += "<option value='" + strArr[i] + "'>" + strArr[i] + '</option>';
      }
    }
    document.getElementById('newsletterSelect').innerHTML = newsletterSelect;
    if (strArr.length <= 1 || strArr.length <= 'null') {
      var element = document.getElementById('newsletterSelectComponent');
      element.parentElement.removeChild(element);
    }

  },
  methods: {
    closeDropdown() {
      this.hideDropdown = false;
      this.isSkinEmpty();
    },
    showDropdown() {
      if (this.hideDropdown == true) {
        this.hideDropdown = false;
        this.isSkinEmpty();
      } else {
        this.hideDropdown = true;
      }
    },
    onCaptchaAPIReady() {
      /* istanbul ignore next */
      setTimeout(() => {
        if (typeof grecaptcha === 'undefined' && typeof grecaptcha.render !== 'function') {
          this.onCaptchaAPIReady();
        } else {
          grecaptcha.render('letter-recaptcha', {
            sitekey: this.siteKey,
            callback: this.checkCaptcha
          });
        }
      }, 200);

    },
    checkCaptcha() {
      grecaptcha.getResponse().length !== 0 ? this.hasErrorCapcha = false : this.hasErrorCapcha = true;
      this.captchaValue = grecaptcha.getResponse();
    },
    isEmailEmpty() {
      if (this.emailField === '') {
        this.hasErrorEmail = true;
      } else {
        this.hasErrorEmail = false;
      }
    },
    isFirstNameEmpty() {
      if (this.firstnameField === '') {
        this.hasErrorFirstName = true;
        this.hasFirstName = true;
      } else {
        this.hasErrorFirstName = false;
        this.hasFirstName = false;
      }
    },
    isSkinEmpty() {
      if (this.inputs.length > 0) {
        this.hasSkinEmpty = false;
      } else {
        this.hasSkinEmpty = true;
      }
    },

    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    checkEmailValid() {
      if (!this.validateEmail(this.emailField) && this.emailField !== '') {
        this.hasErrorValidEmail = true;
      } else {
        this.hasErrorValidEmail = false;
      }
    },
    checkTermsValid() {
      if (!this.termsField) {
        this.hasErrorTerms = true;
      } else {
        this.hasErrorTerms = false;
      }
    },
    sendEmail(email, country, firstname, skin, month, year, captcha, captchaon) {
      skin = skin.toString();
      return postData(this.fetchUrl, {
        'email': email,
        'country': country,
        'firstname': firstname,
        'gdprPurpose': true,
        'skinConcern': skin,
        'DOBMonth': month,
        'DOBYear': year,
        'captchaKey': captcha,
        'captchaEnabled': captchaon,
      });
    },
    validateAndSend() {
      this.isEmailEmpty();
      this.isFirstNameEmpty();
      this.checkTermsValid();
      this.isSkinEmpty();
      if (!!this.siteKey) {
        this.checkCaptcha();
        this.captchaEnabled = true;
        console.log(this.captchaValue);

        if (!!this.emailField && !!this.firstnameField && !this.hasSkinEmpty && this.termsField && !this.hasErrorCapcha) {
          this.isSumbitEnabled = false;
          this.sendEmail(this.emailField, this.countryField, this.firstnameField, this.inputs, this.monthField, this.yearField, this.captchaValue, this.captchaEnabled)
              .then(response => this.serverResponse = response);
        }
      } else {
        this.captchaEnabled = false;
        if (!!this.emailField && !!this.firstnameField && !this.hasSkinEmpty && this.termsField) {
          this.isSumbitEnabled = false;
          this.sendEmail(this.emailField, this.countryField, this.firstnameField, this.inputs, this.monthField, this.yearField, this.captchaValue, this.captchaEnabled)
              .then(response => this.serverResponse = response);
        }
      }

    }
  }
};
</script>
<style lang='scss' src="./subscription.scss"></style>
