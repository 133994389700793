import subscription from '@Feature/NewsletterIntegration/code/Scripts/components/subscription.vue';

const components = [subscription];

const APACCustomizations = {
  install(localVue) {
    components.map((component) => localVue.component(component.name, component));
  },
};

/**
 * Expose plugin name on `window` to enable manual installation — master driven
 */
if (typeof window !== 'undefined') {
  window.LOCAL_ZONE_PLUGIN_NAME = process.env.VUE_APP_LOCAL_ZONE_PLUGIN_NAME;

  /**
   * Auto-install the plugin if global Vue is available
   *
   * @see https://www.digitalocean.com/community/tutorials/vuejs-creating-custom-plugins (Supporting automatic installation)
   */
  if (window.Vue) {
    window.Vue.use(APACCustomizations);
  }
}

export default APACCustomizations;
